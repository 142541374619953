import { useMemo } from 'react';
import Modal from 'react-modal';
import './DeleteAssetModal.less';

export const DeleteAssetModal = ({
  open,
  setOpen,
  assetName,
  onDeleteAsset,
}: {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  assetName: string;
  onDeleteAsset: () => void;
}) => {
  const styles = useMemo(() => {
    return {
      modalStyle: {
        borderRadius: 24,
        overflow: 'hidden',
        border: '1px solid #FFF',
      },
      modalBody: {
        background: '#001922',
        padding: 40,
      },
    } as const;
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      isOpen={open}
      className="Modal"
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      <div className="delete-asset-modal">
        <span onClick={onClose} className="icon-close">
          X
        </span>
        <h1>Delete Asset</h1>
        <p>
          Are you sure you want to delete the asset &quot;{assetName}&quot;? This action cannot be undone.
        </p>

        <div className="delete-asset-modal__buttons">
          <button onClick={onClose} className="delete-asset-modal__button cancel">
            Cancel
          </button>
          <button onClick={onDeleteAsset} className="delete-asset-modal__button delete">
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAssetModal;
