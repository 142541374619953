import React, { useState, useEffect, useContext } from 'react';
import axios, { AxiosError } from 'axios';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './MyAssetsTab.less';
import { UserContext } from '../../../data/userContext';

interface Asset {
  id: string;
  url: string;
  type: string; // 'image' | 'video' | 'watermark'
  name: string;
  thumbnail?: string; // Optional field for video thumbnails
}

interface IMyAssetsTabProps {
  handleChooseImage: (url: string, name: string, ratio: number,id: string) => void;
  handleChooseVideo: (url: string, name: string, duration: number, ratio: number,id: string) => void;
  handleChooseWatermark: (imageUrl: string, ratio: number, position: string) => void;
  handleDeleteFeatureSlider: (id: any) => void;
}

const MyAssetsTab = ({
  handleChooseImage,
  handleChooseVideo,
  handleChooseWatermark,
  handleDeleteFeatureSlider
}: IMyAssetsTabProps) => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const [loading, setLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const userContext = useContext(UserContext);

  useEffect(() => {
    fetchAssets();
  }, []);

  const fetchAssets = async () => {
    try {
      const response = await axios.get('/streamer/assets');
      const assets = response.data || [];
  
      const assetsWithThumbnails = await Promise.all(
        assets.map((asset: Asset) => {
          return new Promise<Asset>((resolve) => {
            if ((asset.type.toLowerCase() === 'video' || asset.type.toLowerCase() === 'gif') && !asset.thumbnail) {
              generateThumbnail(asset.url, (thumbnail) => {
                resolve({ ...asset, thumbnail });
              });
            } else {
              resolve(asset);
            }
          });
        })
      );
  
      setAssets(assetsWithThumbnails);
    } catch (error) {
      messageApi.error('Failed to fetch assets. Please try again.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  



  const generateThumbnail = (videoUrl: string, callback: (thumbnailUrl: string) => void): void => {
    const video = document.createElement('video');
    video.src = `${videoUrl}?_=${new Date().getTime()}`; // Cache busting
    video.crossOrigin = 'anonymous';
    video.style.display = 'none';
    document.body.appendChild(video);

    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      console.warn('Thumbnail generation timed out.');
      callback('path/to/placeholder-image.jpg');
      cleanup();
    }, 5000); // Timeout after 5 seconds

    const cleanup = () => {
      clearTimeout(timeoutId); // No reassignment here, so 'const' is fine
      video.remove();
    };

    video.addEventListener('loadeddata', () => {
      video.currentTime = 0.5; // Seek to avoid black frames
    });

    video.addEventListener('seeked', () => {
      clearTimeout(timeoutId); // No reassignment here, so 'const' is fine
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth * 0.5;
      canvas.height = video.videoHeight * 0.5;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          (blob) => {
            const thumbnailUrl = blob ? URL.createObjectURL(blob) : 'path/to/placeholder-image.jpg';
            callback(thumbnailUrl);
            cleanup();
          },
          'image/jpeg',
          0.8
        );
      } else {
        callback('path/to/placeholder-image.jpg');
        cleanup();
      }
    });

    video.addEventListener('error', () => {
      callback('path/to/placeholder-image.jpg');
      cleanup();
    });

    video.load();
  };







  const handleClick = (asset: Asset) => {
    const { url, type, name, id } = asset;

    if (type.toLowerCase() === 'image' || type.toLowerCase() === 'watermark') {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        const ratio = img.width / img.height || 1;
        handleChooseImage(url, name, ratio,id);
      };
      img.onerror = () => messageApi.error('Failed to load image.');
    } else if (type.toLowerCase() === 'video') {
      const video = document.createElement('video');
      video.src = url;

      video.onloadedmetadata = () => {
        const duration = video.duration;
        const ratio = video.videoWidth / video.videoHeight || 16 / 9;
       handleChooseVideo(url, name, duration, ratio, id)
      };

      video.onerror = () => messageApi.error('Failed to load video.');
    } else if (type.toLowerCase() === 'gif') {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        const ratio = img.width / img.height || 1;
        handleChooseImage(url, name, ratio,id); // Reuse the handleChooseImage function
      };
      img.onerror = () => messageApi.error('Failed to load GIF.');
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const { status } = await axios.delete('/streamer/asset', { data: { id } })
  
      if (status >= 200 && status < 300) {
        messageApi.success('Asset deleted successfully!');
        setAssets((prevAssets) => prevAssets.filter((asset) => asset.id !== id));
        handleDeleteFeatureSlider?.(id);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response || axiosError.request) {
        messageApi.error('Failed to delete asset. Please check your network.');
      } else {
        console.error('Unexpected Error:', error);
      }
    }
  };
  
  

  return (
    <div className="my-assets-tab-container">
      {contextHolder}
      {loading ? (
        <div className="loading-container">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 42, color: '#fff' }} spin />} />
        </div>
      ) : (
        <div className="assets-grid">
          {assets.map((asset) => (
            <div key={asset.id} className="asset-item">
              <div
                className="delete-icon-container"
                onClick={() => handleDelete(asset.id)}
                title="Delete Asset"
              >
                ✕
              </div>
              {asset.type.toLowerCase() === 'image' || asset.type.toLowerCase() === 'watermark' || asset.type.toLowerCase() === 'gif' ? (
                <img
                  src={asset.url}
                  alt={asset.name}
                  className="asset-preview"
                  onClick={() => handleClick(asset)}
                />
              ) : asset.type.toLowerCase() === 'video' ? (
                <img
                  src={asset.thumbnail || asset.url}
                  alt={asset.name}
                  className="asset-preview"
                  onClick={() => handleClick(asset)}
                />
              ) : null}

            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyAssetsTab;
