import { useMemo, useEffect, useContext, useState, useRef } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { Layout, Col, Typography, Button, Image, Input, Spin, message } from 'antd';
import { UserContext } from '../../data/userContext';
import { isMobile } from 'react-device-detect';
import Typical from 'react-typical';
import axios from 'axios';
import { Link, Element } from 'react-scroll';
import { Helmet } from 'react-helmet';
import { STRUCTURED_DATA_FAQ, STRUCTURED_DATA_HOME_PAGE } from '../../structured-data';
import CryptoJS from 'crypto-js';

import FooterResponsive from '../../components/footer/FooterResponsive';
import HeaderResponsive from '../../components/header/header-responsive/HeaderResponsive';
import { LoginModal } from '../../components/login-modal';
import { ReactComponent as IconInfoCircle } from '../../assets/info-circle.svg';

import Faq from './Home-Sections/faq/Faq';
import AboutUs from './Home-Sections/about-us/AboutUs';

import handleTwitchLogin from '../../utils/twitchAuth';
import { ReactComponent as IconTwitch } from '../../assets/icon-twitch-black.svg';
import HomepageAsset from '../../assets/homepage-starting-asset.svg';
import BoxedArrowUp from '../../assets/boxed-arrow-up.svg';
import BoxedArrowDown from '../../assets/boxed-arrow-down.svg';
import PageScrollerAsset from '../../assets/page-scroller-asset.svg';
import SearchIcon from '../../assets/search-icon.svg';
import ProductOfTheWeek from '../../assets/product-of-the-week.svg';


import './Home.less';
import { COLORS } from '../../themes/colors';
import HomepageFeatures from './Home-Sections/homepage-features/HomepageFeatures';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { postAudit } from '../../services/audit.service';

const HomePage = () => {
  const { Title, Text } = Typography;
  const { Content } = Layout;

  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState<string>('home-section');
  const [nextSection, setNextSection] = useState<string>('about-section');
  const [prevSection, setPrevSection] = useState<string>('');
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [inputUrl, setInputUrl] = useState<string>('');
  const twitchCode = searchParams.get('code');
  const isEmail = searchParams.get('isEmail');
  const loginOrRegosterSuccess = searchParams.get('loginOrRegosterSuccess');

  const refCode = searchParams.get('ref');
  const authUser = searchParams.get('authuser');
  const error = searchParams.get('error');
  const campaignUrl = localStorage.getItem('campaign_url');

  const subscriptions = userContext?.user?.subscriptions;
  const hideLoginModals = localStorage.getItem('hideLoginModals');
  const onbordingFinished = userContext?.user?.referrer_source || userContext?.user?.category;
  const [messageApi, contextHer] = message.useMessage();
  const isGoogleLogin = !!authUser;
  const loginMethod = localStorage.getItem('login_method');

  const rotatingWords = [
    'One-Click Edits',
    2000,
    'Viral Clips',
    2000,
    'Memorable Highlights!',
    2000,
  ];


  useEffect(() => {
    const loginOrRegister = async () => {
      if (loginOrRegosterSuccess) {
        if (onbordingFinished) {
          navigate('/spikes/videos');
        } else {
          navigate('/onboarding');
        }
      }
    };

    if (isEmail) {
      loginOrRegister();
    }
  }, [isEmail]);

  useEffect(() => {
    const loginOrRegister = async () => {
      setLoading(true);
      const payload: any = {
        code: twitchCode + (loginMethod === 'facebook' ? '#_=_' : ''),
        referral_key: localStorage.getItem('ref') || '',
        is_mobile_login: isMobile,
        type: loginMethod,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...(window.innerWidth > 1024 && { first_desktop_visit: true })
      }

      if (campaignUrl) payload.register_referral = campaignUrl;

      const response = await axios
        .post('/auth/social/twitch/login-or-register', payload)
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            console.log(error.response);
          }
          setIsLoginFailed(true);

          if (isGoogleLogin) {
            navigate('/?google=true')
          }
        });

      if (response?.status === 200 && response?.data?.user) {
        setLoading(false);
        localStorage.removeItem('ref');
        localStorage.removeItem('campaign_url');
        localStorage.removeItem('login-method');
        userContext?.setUser(response.data.user);
        triggerSignUpAnalytics(response.data.user);

        if (onbordingFinished) {
          navigate('/spikes/videos');
        } else {
          navigate('/onboarding');
        }
      } else {
        messageApi.error('Failed to login. Please try again.', 5);
        navigate('/');
      }
    };

    if (twitchCode) {
      loginOrRegister();
    }
  }, [twitchCode]);

  useEffect(() => {
    if (refCode) {
      localStorage.setItem('ref', refCode);
    }
  }, [refCode]);

  useEffect(() => {
    postAudit({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      user_action: `login error - google - error code ${error}`,
      user_id: userContext?.user?.id
    });
  }, [error])

  const triggerSignUpAnalytics = (user: any) => {
    const currentTimestamp = Date.now();
    const userCreatedAt = user?.createdAt;
    const userPlatform = user?.streaming_platform;
    const email = user?.email;
    const firstName = user?.firstname;
    const lastName = user?.lastname;
    const country = user?.country;
    const first_desktop_visit = user?.first_desktop_visit;
    const city = user?.city;
    if (userCreatedAt) {
      const providedDate = new Date(userCreatedAt);
      const timeDifference = currentTimestamp - providedDate.getTime();
      const secondsDifference = timeDifference / 1000;
      if (secondsDifference <= 20) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event_name: 'sign_up',
          method: userPlatform,
          event: 'fireEvent',
          country: country,
          city: city,
          first_name: CryptoJS.SHA256(firstName).toString(CryptoJS.enc.Hex),
          last_name: CryptoJS.SHA256(lastName).toString(CryptoJS.enc.Hex),
          email: CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex),
        });
      }
      if (window.innerWidth >= 1024 && first_desktop_visit) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event_name: 'deskptop_visit',
          event: 'fireEvent',
          first_name: CryptoJS.SHA256(firstName).toString(CryptoJS.enc.Hex),
          last_name: CryptoJS.SHA256(lastName).toString(CryptoJS.enc.Hex),
          email: CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex),
          country: country,
          city: city
        });
      }
    }
  };




  const styles = useMemo(() => {
    return {
      mainTitle: {
        fontFamily: 'Poppins',
        textAlign: 'left',
        color: '#fff',
        fontSize: '40px',
        marginBottom: '0',
        lineHeight: '40px'
      },
      rotatingText: {
        fontFamily: 'Poppins',
        textAlign: 'left',
        color: '#FFF500',
        lineHeight: '40px !important',
        fontSize: '40px',
        marginTop: 0,
        marginBottom: 0,
        display: 'flex',
        alignItems: 'center'
      },
      subtitle: {
        fontFamily: 'Poppins',
        fontSize: 24,
        fontWeight: 300,
        lineHeight: '30px',
      },
      buttonContainer: {
        marginTop: 34
      },
      videoContainer: {
        maxWidth: '350px',
      },
      topContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      topButton: {
        padding: '12px 32px',
        fontSize: 28,
        fontWeight: 600,
        height: 76
      },
      waitlistButton: {
        background: '#67FFCC',
        border: '2px solid #000',
        color: '#000',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '800',
        height: '60px',
        marginTop: '20px',
      },
      mobileInput: {
        background: COLORS.GRAY_BACKGROUND,
        fontSize: 16,
        height: 48,
        padding: '0 25px',
        paddingLeft: 77,
        borderRadius: '8px',
        marginBottom: 7
      },
      loadingWrapper: {
        height: '100vh',
        width: '100%',
        backgroundColor: 'var(--background)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      },
      loadingContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 15
      }
    } as const;
  }, []);

  return (
    <div style={styles.loadingWrapper}>
      {!isLoginFailed ?
        <div style={styles.loadingContent}>
          <Spin style={{ marginBottom: 20 }} indicator={<LoadingOutlined style={{ fontSize: 42, color: 'var(--text-base)' }} spin />} />
          <h1 style={{ color: 'var(--text-base)' }}>Logging In...</h1>
        </div>
        :
        <div style={styles.loadingContent}>
          <IconInfoCircle style={{ marginBottom: 20, height: 50, width: 50, color: 'var(--text-base)' }} />
          <h1 style={{ textAlign: 'center', color: 'var(--text-base)' }}>We&apos;re having issues logging you in. Sorry for the inconvenience, please try to login again.</h1>
        </div>
      }

    </div>
  );


  const DesktopContent = () =>
    <div className="topContent" style={styles.topContent}>
      <Col style={{ flex: 'auto' }} span={24}>
        <Title
          className="main-title rotate-title"
          style={styles.mainTitle}
          level={1}
        >
          From Long Videos
        </Title>
        <Typography
          className="main-title rotate-title"
          style={{ ...styles.rotatingText, marginTop: -10, justifyContent: 'center' }}
        >
          <span style={{ color: COLORS.WHITE, fontSize: 'inherit', paddingBottom: 4 }}>To &nbsp;</span>
          {/* <Typical
        steps={rotatingWords}
        loop={Infinity}
        wrapper="span"
      /> */}
          <span>Viral Clips</span>
        </Typography>
      </Col>
      <div className="videoContainer" style={styles.videoContainer}>
        <video style={{ borderRadius: 5 }} height="100%" width="100%" muted autoPlay loop playsInline>
          <source
            src="https://d2dgwhnk34cu0y.cloudfront.net/main-video.mp4"
            type="video/mp4"
          />
          <p>Functionality preview video</p>
        </video>
      </div>
      <Image src={HomepageAsset} preview={false} width={360} style={{ margin: '25px 0' }} />

      <Text className="subtitle" style={styles.subtitle}>
        Why risk losing your best moments?
      </Text>
      <Text className="subtitle" style={styles.subtitle}>
        Repurpose all your content into viral clips for
      </Text>
      <Text className="subtitle" style={styles.subtitle}>
        YouTube, TikTok, and Instagram Reels!
      </Text>
      <div style={styles.buttonContainer}>
        {userContext?.user ? (
          userContext?.user.in_waitlist ? (
            <Button
              className="button"
              type='primary'
              style={styles.topButton}
              onClick={() => navigate('/affiliate-program')}
            >
              Waitlist Status
            </Button>
          ) : (
            <Button
              className="button"
              type='primary'
              style={styles.topButton}
              onClick={() => navigate('/spikes/videos')}
            >
              My Spikes
            </Button>
          )
        ) : (
          <Button
            className="button"
            type='primary'
            style={styles.topButton}
            onClick={() => setShowLoginModal(true)}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginLeft: 2 }}>
                Start Now
              </span>
            </div>
          </Button>
        )}
      </div>
    </div>;

  const MobileContent = () =>
    <div className="topContent mobile" style={styles.topContent}>
      <Col style={{ flex: 'auto' }} span={24}>
        <Typography
          className="title-text"
        >
          From Long Videos
        </Typography>
        <Typography
          className="title-text"
        >
          <span>To &nbsp;</span>
          <span style={{ color: '#FFF500' }}>Viral Clips</span>
        </Typography>
        <Typography
          className="title-text"
        >
          in 1 click
        </Typography>
      </Col>
      <Typography className="heading-label">Generative Ai video tool that repurposes long videos to shorts in just one click</Typography>

      {userContext?.user ?
        <Button
          className="button top-cta"
          type='primary'
          onClick={() => navigate('/spikes/videos')}
        >
          My Spikes
        </Button>
        :
        <div style={{ position: 'relative' }}>
          {/* <Image style={{ position: 'absolute', top: -24, left: 17, height: 40, width: 40, zIndex: 9 }} src={<SearchOutlined />} preview={false} /> */}
          <SearchOutlined style={{
            position: 'absolute',
            top: 8,
            left: 21,
            fontSize: 30,
            color: '#212121',
            zIndex: 9
          }} />
          <Input placeholder="Search for Youtube video" style={styles.mobileInput} />
          <Button
            className="button top-cta"
            type='primary'
            onClick={() => setShowLoginModal(true)}
          >
            Get free clips
          </Button>
          <Typography className="mobile-button-label">or upload videos from your desktop</Typography>
        </div>
      }

      <Typography className="ai-curation-text">AI Curation</Typography>
      <div className="videoContainer" style={styles.videoContainer}>
        <video style={{ borderRadius: 5 }} height="100%" width="100%" muted autoPlay loop playsInline>
          <source
            src="https://d2dgwhnk34cu0y.cloudfront.net/main-video-mobile.mp4"
            type="video/mp4"
          />
          <p>Functionality preview video</p>
        </video>
      </div>

      <Typography className="featured-text">Featured</Typography>
      <Image src={ProductOfTheWeek} preview={false} />
    </div>;

  return (
    <>
      <Helmet>
        <title>Spikes Studio | Ai Video Editor & Highlights | Fast Upload</title>
        <link rel="canonical" href="https://spikes.studio/" />
        <meta name="description" content="Spikes AI is a smart system that analyzes sound, video, and even your gameplay. This smart video editor highlights the best parts and is fast on upload. " />
        <script type="application/ld+json">
          {JSON.stringify(STRUCTURED_DATA_HOME_PAGE)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(STRUCTURED_DATA_FAQ)}
        </script>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      {(subscriptions == null || subscriptions?.length == 0) && userContext?.user && !userContext?.user?.in_waitlist &&
        <div className="homepage-go-pro" onClick={() => navigate('/subscriptions')}>SPECIAL OFFER</div>
      }

      {/* <PageScroller setActiveSection={setActiveSection} /> */}
      <Layout className="mainContainer main-reduced-padding">
        <HeaderResponsive />
        <Element name="home-section">
          <Content className="topContainer">
            {isMobile ? <MobileContent /> : <DesktopContent />}
          </Content>
        </Element>
        <AboutUs />
        <HomepageFeatures />
        <Faq />
        <FooterResponsive />
        {showLoginModal && <LoginModal open={showLoginModal} onClose={() => setShowLoginModal(false)} />}
      </Layout>
    </>
  );
};

const PageScroller = ({ setActiveSection }: { setActiveSection: (section: string) => void; }) => {
  const handleSetActive = (section: string) => {
    setActiveSection(section);
  };

  return (
    <div className="page-scroller">
      <div className="cursor-pointer">
        <Image src={BoxedArrowUp} preview={false} width={40} />
      </div>
      <div className="page-scroller__main">
        <Image src={PageScrollerAsset} preview={false} />
        <div className="page-scroller__links-list">
          <Link
            activeClass="page-scroller__link-active"
            to="home-section"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onSetActive={handleSetActive}
          >
            <Typography className="page-scroller__link">Home</Typography>
          </Link>
          <Link
            activeClass="page-scroller__link-active"
            to="about-section"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onSetActive={handleSetActive}
          >
            <Typography className="page-scroller__link">About</Typography>
          </Link>
          <Link
            activeClass="page-scroller__link-active"
            to="features-section"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
            onSetActive={handleSetActive}
          >
            <Typography className="page-scroller__link">Features</Typography>
          </Link>
          <Link
            activeClass="page-scroller__link-active"
            to="faq-section"
            spy={true}
            smooth={true}
            duration={500}
            onSetActive={handleSetActive}
          >
            <Typography className="page-scroller__link">FAQ</Typography>
          </Link>
        </div>
      </div>
      <div className="cursor-pointer">
        <Image src={BoxedArrowDown} preview={false} width={40} />
      </div>
    </div>
  );
};

export default HomePage;
